/*!

=========================================================
* Material Dashboard Angular - v2.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-angular2
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-angular2/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
.tim-typo{
  padding-left: 25%;
  margin-bottom: 40px;
  position: relative;
  width: 100%;
}
.tim-typo .tim-note{
  bottom: 5px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
}


.bg-slider-1 {

  background-size: cover;
  color: white;
}

.bg-slider-2 {


  background-size: cover;
  color: white;
}

.bg-slider-3 {

  background-size: cover;
  color: white;
}

@media(max-width:767px) {
  .header-top {
      display: none
  }

  .mobile-hidden {
    display: none;
  }

  .mobile-row {
    margin-bottom: 8px;
  }
}

.header-wrapper {
background: #fff;
}

.invalid-select {
  color: #dc3545;
}
.is-invalid {
    border-color: #dc3545;
    font-size: 14px;
    text-align: left !important;
}
.mt-10{
    margin-top: 10px !important;
  }
  .mt-15{
    margin-top: 15px !important;
  }
  .mt-20{
    margin-top: 20px !important;
  }
  .mt-25{
    margin-top: 25px !important;
  }
  .mt-30{
    margin-top: 30px !important;
  }
  .mt-40{
    margin-top: 40px !important;
  }
  .mt-50{
    margin-top: 50px !important;
  }
  .mt-100{
    margin-top: 100px !important;
  }
  .m-10 {
    margin: 10px !important;
  }
  .mlr-10{
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .p-10 {
    padding: 10px !important;
  }
  .plr-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .color-success{
    background: linear-gradient(60deg, #66bb6a, #43a047);
  }
  .text-green {
    color:#66bb6a;
  }
  .text-amber{
    color:#ffb300;
  }
  .text-blue{
    color: #2196f3;
  }
